import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "./Images/logo192.svg";
// import { KEYCHAIN } from '../utils/keychain';

const S3Image = ({ imageKey, title }) => {
  const [imageData, setImageData] = useState(null);

  console.log(imageKey);

  const onImageError = (e) => {
    e.target.src = defaultImage;
  };
  useEffect(() => {
    console.log(imageKey);
    const fetchImageData = async () => {
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: "biteluxe",
          Key: imageKey,
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);

  if (!imageData) {
    return (
      <div>
        <img height={60} width={60} src={defaultImage} />
      </div>
    );
  }

  return (
    <div style={{ height: "50px", width: "175px" }}>
      <img
        style={{
          // borderRadius: "35px",
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        className="profile-img"
        src={`data:image/png;base64,${imageData}`}
        alt="S3 Content"
        onError={onImageError}
      />
    </div>
  );
};

export default S3Image;
