import { useState } from "react";
import { useLocation } from "react-router-dom";
import S3Image from "./S3Image";
import ThankyouModal from "./ThankyouModal";

export default function Thankyou(props) {
  const location = useLocation();
  const feedbackData = location.state;
  // const logo = location.logo;

  const [thankyouModal, setThankyouModal] = useState(false);
  console.log(feedbackData);
  return (
    <>
      <div className="d-flex justify-content-center p-3">
        <S3Image imageKey={feedbackData?.logo} />
      </div>
      <div className="thankyou-container">
        <div className="thank-container">
          <h1>{feedbackData?.submitPage?.headline}</h1>
          <p>{feedbackData?.submitPage?.h1}</p>
          <p1>{feedbackData?.submitPage?.h2}</p1>
          <p className="mt-3">{feedbackData?.submitPage?.h3}</p>
        </div>
      </div>
      <p className="feedback" onClick={() => setThankyouModal(true)}>
        How do we use your feedback?
      </p>

      <ThankyouModal
        show={thankyouModal}
        onHide={() => setThankyouModal(false)}
        item={feedbackData}
      />
    </>
  );
}
