import "./App.css";
import RatingPage from "./RatingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThankYouPage from "./ThankYouPage";
import AWS from "aws-sdk";
import { CognitoIdentityCredentials } from "aws-sdk";

function App() {
  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    credentials: new CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_POOL_ID,
    }),
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RatingPage />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
