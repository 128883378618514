import React from "react";
import { Modal } from "react-bootstrap";

export default function ThankyouModal({ show, onHide, item }) {
  console.log(item);
  return (
    <Modal className="modal fade p-3" show={show} onHide={onHide} centered>
      <div className="feedback-content">
        <h6>{item?.privacyHeading}</h6>
        <p>{item?.privacyStatement}</p>
      </div>
    </Modal>
  );
}
