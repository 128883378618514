import React, { useState, useEffect } from "react";
import ReactStars from "react-stars";
import "./App.css"; // Import your CSS file for styling
import ThankyouModal from "./ThankyouModal";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import S3Image from "./S3Image";

const App = () => {
  const [snapGroupId, setSnapGroupId] = useState("");
  const [hotelId, setHotelId] = useState("");
  const [data, setData] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [thankyouModal, setThankyouModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [commentsData, setCommentsData] = useState({
    name: "",
    comments: "",
    roomNumber: "",
  });
  const navigate = useNavigate();
  const [questionState, setQuestionState] = useState(null);

  useEffect(() => {
    const hId = queryParams.get("hId");
    const sId = queryParams.get("sId");

    if (hId) {
      setHotelId(hId);
    }

    if (sId) {
      setSnapGroupId(sId);
    }
  }, [location.search]);
  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `https://api.microsurvey.uk/admin/user/survey?hId=${hotelId}&sId=${snapGroupId}`
      );
      setData(response?.data?.data);
      setQuestionState(response?.data?.data?.questions);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };
  const handleRatingChange = (index, value) => {
    const newReviewsState = [...questionState];
    newReviewsState[index].review = value;
    setQuestionState([...newReviewsState]);
  };

  function getFilteredReviewsArray() {
    let filteredArray = questionState.map((question) => ({
      question: question.question,
      review: question.review,
    }));

    return filteredArray;
  }

  async function handleSubmitRating(e) {
    e.preventDefault();
    setLoader(true);
    try {
      const postData = {
        snapGroupId: data?.snapGroupId,
        hotelId: data?.hotel?._id,
        profileId: data?.hotel?.profile,
        reviews: getFilteredReviewsArray(),
        areaId: data?.area?.area,
        categoryId: data?.categoryId,
        comments: commentsData?.comments,
        name: commentsData?.name,
        roomNumber: commentsData?.roomNumber,
      };
      const res = await axios.post(
        "https://api.microsurvey.uk/admin/user/survey",
        postData
      );
      if (res?.status === 200) {
        // toast.success("Sub-Subtopic added successfully");
        navigate("/thankyou", {
          state: data?.hotel,
        });
      } else if (res?.status !== 200) {
        throw new Error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (hotelId) {
      fetchData();
    }
  }, [hotelId]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className="d-flex align-items-center flex-column justify-content-center">
      <div className="main-container">
        <div className="">
          <S3Image imageKey={data?.hotel?.logo} />
        </div>
        <div className="main-heading mt-3">
          <h1>{data?.categoryName}</h1>
        </div>
      </div>
      <>
        {data?.area ? (
          <>
            <div className="rating-container">
              <>
                {questionState &&
                  questionState.map((rating, index) => (
                    <div key={index} className="rating">
                      <p className="m-0">{rating?.question}</p>
                      <ReactStars
                        count={5}
                        value={
                          questionState.find(
                            (review) => review._id === rating._id
                          )?.review || 0
                        }
                        onChange={(value) => handleRatingChange(index, value)}
                        size={38}
                        color2={"#ffd700"}
                        half={false}
                        classNames="custom-stars"
                      />
                    </div>
                  ))}
              </>
            </div>
            <div className="questions">
              <input
                type="text"
                placeholder={data?.hotel?.commentsPlaceholder}
                onChange={(e) =>
                  setCommentsData({
                    ...commentsData,
                    comments: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder={data?.hotel?.surnamePlaceholder}
                onChange={(e) =>
                  setCommentsData({
                    ...commentsData,
                    name: e.target.value,
                  })
                }
              />
              <input
                type="number"
                placeholder={data?.hotel?.roomNumberPlaceholder}
                onChange={(e) =>
                  setCommentsData({
                    ...commentsData,
                    roomNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="submit">
              <button onClick={handleSubmitRating}>
                {loader ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "SUBMIT FEEDBACK"
                )}
              </button>
            </div>
          </>
        ) : (
          <div>
            <h4 style={{ color: "#432968" }}>LOADING !</h4>
          </div>
        )}
      </>
      <p className="feedback" onClick={() => setThankyouModal(true)}>
        How do we use your feedback?
      </p>
      {ThankyouModal && (
        <ThankyouModal
          show={thankyouModal}
          onHide={() => setThankyouModal(false)}
          item={data?.hotel}
        />
      )}
    </div>
  );
};

export default App;
